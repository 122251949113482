import React from 'react'
import { css } from '@emotion/core'
import IconInline from './IconInline'

const AvailableFlowClasses = ({ flowClasses }) => {
  if (!flowClasses || !flowClasses.length) {
    return null
  }
  return (
    <div
      css={css`
        text-align: right;
        margin-bottom: 10px;
      `}
      className="available-flow-classes"
    >
      {flowClasses.map((flowClass) => {
        const flowClassIsolated = flowClass.split(' ')[0]
        return (
          <a
            href={`/workflows/${flowClassIsolated.toLowerCase()}-workflows/`}
            css={css`
              display: inline-block;
              margin-left: 25px;
              color: #444 !important;
              font-size: 14px;
            `}
            key={flowClassIsolated}
          >
            <IconInline
              glyph={`FLOW_CLASS_${flowClassIsolated.toUpperCase()}`}
            />
            {flowClass}
          </a>
        )
      })}
    </div>
  )
}

export default AvailableFlowClasses
